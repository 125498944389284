@import "../../global";

.mobileMenu {
  margin: auto;

  &Nav {
    display: grid;
    gap: $xl;

    & > a,
    & > button {
      font-size: 2em;
      font-weight: 500;
      line-height: normal;

      span {
        background-color: var(--secondary-border-color);
        color: var(--bg-color);
        display: inline-block;
        padding: 0 $sm;
      }
    }
  }
}
