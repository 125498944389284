// todo: THIS IS TO GO!!!!
// todo: THIS IS TO GO!!!!
// todo: THIS IS TO GO!!!!
// todo: THIS IS TO GO!!!!
// todo: THIS IS TO GO!!!!
// todo: THIS IS TO GO!!!!
$increment: 4px;

$xs: calc($increment * 0.5);
$sm: calc($increment * 1);
$md: calc($increment * 2);
$lg: calc($increment * 4);
$xl: calc($increment * 6);
$xxl: calc($increment * 8);

$headerheight: 50px; // todo: hack

$transition: 0.15s ease-in-out;

$contentMaxWidth: 576px;

@mixin xs {
  @media (max-width: 575px) {
    @content;
  }
}

@mixin sm {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin md {
  @media (min-width: 768px) and (max-width: 991px) {
    @content;
  }
}

@mixin mdAndUp {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin lg {
  @media (min-width: 992px) and (max-width: 1199px) {
    @content;
  }
}

@mixin xl {
  @media (min-width: 1200px) {
    @content;
  }
}
// todo: THIS IS TO GO!!!!
// todo: THIS IS TO GO!!!!
// todo: THIS IS TO GO!!!!
// todo: THIS IS TO GO!!!!
// todo: THIS IS TO GO!!!!
// todo: THIS IS TO GO!!!!
