.blogPostList {
  h1 {
    text-align: center;
  }

  &Description {
    color: var(--secondary-text-color);
    text-align: center;
  }
}
