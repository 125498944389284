@import "../../global";

.footer {
  border-top: 1px solid var(--border-color);
  display: grid;
  padding: $lg;
}

.socials {
  h6 {
    display: block;
    margin-bottom: $md;
  }

  a {
    display: inline-block;
    margin-bottom: $md;
    margin-right: $md;
  }

  &Button {
    &GitHub {
      color: #333;

      &:hover {
        background-color: #333;
        border-color: #333;
        color: white;
      }
    }
    &LinkedIn {
      color: #0077b5;

      &:hover {
        background-color: #0077b5;
        border-color: #0077b5;
        color: white;
      }
    }
    &NPM {
      color: #cb3837;

      &:hover {
        background-color: #cb3837;
        border-color: #cb3837;
        color: white;
      }
    }
    &Twitter {
      color: #1da1f2;

      &:hover {
        background-color: #1da1f2;
        border-color: #1da1f2;
        color: white;
      }
    }
  }
}
