@import "../../global";

@mixin contentDisplay {
  .contentDisplay {
    margin: auto;
    width: 100%;
    max-width: $contentMaxWidth;

    img,
    svg {
      height: auto;
      margin: auto;
      max-width: 100%;
    }

    p {
      margin: 0;
    }

    pre {
      display: flex;
      margin: 0;
      max-height: 40vh;
      overflow-y: auto;
    }

    code,
    pre {
      background: var(--code-background-color);
      border-radius: $sm;
      color: var(--code-text-color);
      padding: $xs $sm;
    }

    code {
      font-size: 0.8em;
    }

    .fullWidth {
      width: 100vw;
      margin-left: calc(-50vw + 50%);
      margin-right: calc(-50vw + 50%);
    }

    .embeddedEntry {
      h4,
      h5 {
        margin-bottom: $md;
      }

      p {
        margin-bottom: $xl;

        &:last-child {
          margin-bottom: 0;
        }
      }

      ol,
      ul {
        margin-bottom: $xl;
        li {
          margin-bottom: $md;
          p {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  ul {
    margin: 0;
    li {
      list-style-type: square;
    }
  }
}

@include contentDisplay;
